.root {
	--placeholderBg: var(--token-color-page-faint);
	--placeholderBoxBg: var(--token-color-surface-primary);
	--placeholderBoxGradient: linear-gradient(
		90deg,
		rgba(0, 0, 0, 0) 0,
		rgba(0, 0, 0, 0.1) 50%,
		rgba(0, 0, 0, 0) 100%
	);
	
	composes: g-grid from global;
	composes: g-grid-container from global;
	align-items: start;
	row-gap: var(--hdsplus-spacing-05);

	&.dark {
		--placeholderBoxGradient: linear-gradient(
			90deg,
			rgba(255, 255, 255, 0) 0,
			rgba(255, 255, 255, 0.1) 50%,
			rgba(255, 255, 255, 0) 100%
		);
	}

	&.faint {
		--placeholderBg: var(--token-color-page-primary);
		--placeholderBoxBg: var(--token-color-surface-faint);
	}
}

.intro { 
	display: grid;
	grid-column: 1 / -1;

	@media (--medium-up) {
		grid-column: 1 / 6;
	}
}

.eyebrow {
	composes: hdsplus-typography-label from global;
	text-transform: uppercase;
	font-weight: 600;
	margin-bottom: var(--hdsplus-spacing-04);
	color: var(--token-color-foreground-faint);

	@nest :global(.hashidaysConference) & {
		background: linear-gradient(var(--conferenceEyebrowGradient));
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		color: unset;
	}
}

.heading {
	margin: 0 0 var(--hdsplus-spacing-04) 0;
	font-size: var(--hdsplus-typography-display-expressive-400-font-size);
	line-height: var(--hdsplus-typography-display-expressive-400-line-height);
	letter-spacing: var(--hdsplus-typography-display-expressive-400-letter-spacing);

	@media (--medium-up) {
		font-size: var(--hdsplus-typography-display-expressive-500-font-size);
		line-height: var(--hdsplus-typography-display-expressive-500-line-height);
		letter-spacing: var(--hdsplus-typography-display-expressive-500-letter-spacing);
		margin-bottom: var(--hdsplus-spacing-05);
	}

	@media (--large) {
		font-size: var(--hdsplus-typography-display-expressive-600-font-size);
		line-height: var(--hdsplus-typography-display-expressive-600-line-height);
		letter-spacing: var(--hdsplus-typography-display-expressive-600-letter-spacing);
	}
}

.subheading {
	margin: 0 0 var(--hdsplus-spacing-08) 0;
	font-size: var(--hdsplus-typography-display-expressive-100-font-size);
	line-height: var(--hdsplus-typography-display-expressive-100-line-height);
	letter-spacing: var(--hdsplus-typography-display-expressive-100-letter-spacing);

	@media (--medium-up) {
		font-size: var(--hdsplus-typography-display-expressive-200-font-size);
		line-height: var(--hdsplus-typography-display-expressive-200-line-height);
		letter-spacing: var(--hdsplus-typography-display-expressive-200-letter-spacing);
	}

	@media (--large) {
		font-size: var(--hdsplus-typography-display-expressive-300-font-size);
		line-height: var(--hdsplus-typography-display-expressive-300-line-height);
		letter-spacing: var(--hdsplus-typography-display-expressive-300-letter-spacing);
	}
}

.image {
	margin-bottom: var(--hdsplus-spacing-08);
	
	& img {
		vertical-align: middle;
		max-width: 100%;
		height: auto;
	}
}

.form {
	display: grid;
	gap: var(--hdsplus-spacing-06);
	grid-column: 1 / -1;

	@media (--medium-up) {
		grid-column: 7 / -1;
	}
}

.formTitle {
	font-weight: 600;
}

.error {
	color: var(--token-color-foreground-critical);
	display: flex;
	align-items: center;
	gap: var(--hdsplus-spacing-03);
}