.section {
	--background-color-primary-override: var(
		--materia-accent-hero-background-color-primary
	);
}

.root {
	max-width: 100%;
	overflow: hidden;
}

.inner {
	margin: 0 auto;
	position: relative;
	display: grid;
	grid-template-rows: 1fr;
	max-width: 1600px;

	& > * {
		grid-column: 1 / -1;
	}
}

.content {
	composes: g-grid-container from global;
	grid-row: 2;
	z-index: 1;
	display: grid;
	gap: var(--hdsplus-spacing-07);
	padding-bottom: var(--hdsplus-spacing-08);

	@media (--medium-up) {
		grid-row: 1;
		padding-top: var(--hdsplus-spacing-16);
		padding-bottom: var(--hdsplus-spacing-16);
	}
}

.centered {
	justify-content: center;
	text-align: center;

	& .ctas {
		margin: 0 auto;
	}
	& .copy {
		max-width: 900px;
	}
	& .eyebrow {
		justify-content: center;
	}
}

.headingWithEyebrow {
	display: grid;
	gap: var(--hdsplus-spacing-04);
}

.copy {
	display: grid;
	gap: var(--hdsplus-spacing-05);
	max-width: 520px;
}

.logotype {
	& img {
		max-width: 100%;
		height: auto;
	}
}

.eyebrow {
	margin: 0;
	font-weight: 600;
}

.description > *:first-child {
	margin-block-start: 0;
}

.description > *:last-child {
	margin-block-end: 0;
}

.ctas {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: var(--hdsplus-spacing-05) var(--hdsplus-spacing-06);
}

.backgroundImage {
	grid-row: 1;
	justify-self: center;

	@media (--medium-up) {
		position: absolute;
		height: 100%;
	}

	@nest :global(.hashidaysConference) & {
		margin-right: -100px;
	}
	@nest :global(.hashidaysConference) & {
		@media (min-width: 768px) and (max-width: 900px) {
			margin-right: -400px;
		}
		@media (min-width: 900px) and (max-width: 1150px) {
			margin-right: -300px;
		}
	}

	& img {
		max-width: 100%;
		height: 100%;
		object-fit: contain;
		object-position: top right;
	}
}
